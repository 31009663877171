export default defineNuxtRouteMiddleware(async (to, from) => {
  const $localePath = useNuxtApp().$localePath

  const isCheckoutPage = (route: string) => ['/checkout', $localePath('/checkout')].includes(route)

  const result = await fetchActiveOrder()
  const { data: activeOrder } = toRefs(result)

  const hasActiveOrder = computed(() => activeOrder.value && activeOrder.value.active && activeOrder.value.lines.length > 0)

  if (isCheckoutPage(to.path) && !hasActiveOrder.value) {
    if (process.server && to.path === from.path)
      return navigateTo($localePath('/'))
    return navigateTo($localePath('/'))
  }
})
